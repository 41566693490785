import Message from '../Message/Message';

function ErrorPage() {
  return (
    <Message>
      Ошибка 404!<br />
      Страница не найдена.
    </Message>
  )
}

export default ErrorPage;
